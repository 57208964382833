// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "https://cabso.appkodes.in",
  apiUrl: 'https://cabso.appkodes.in:4007',
  socketUrl: 'https://cabso.appkodes.in:8084',
  googleapiKey: "AIzaSyDg38ZuP3-h48YMd-gGoKZ7GvGdMT2q4Ps",
  googleloginClientid: "996339786549-37s386mko1e86vjbgragtv583lbi9s2r.apps.googleusercontent.com",
  facebookloginClientid: "1031467304097204",
  firebase: {
    apiKey: "AIzaSyAa-JQri8exnJp7paxVliLkBLT20Z_PO_c",
    authDomain: "meetdoc-289609.firebaseapp.com",
    databaseURL: "https://meetdoc-289609.firebaseio.com",
    projectId: "meetdoc-289609",
    storageBucket: "meetdoc-289609.appspot.com",
    messagingSenderId: "102060185432",
    appId: "1:102060185432:web:5e8d1bd4f9fa0f7aca772d",
    measurementId: "G-6PJJS15WJY"
  }
};


